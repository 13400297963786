import type { VNode } from 'vue'
import type { ElMessageBoxOptions } from 'element-plus'
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus'
import { format, parseISO, toDate } from 'date-fns'
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx-js-style'
import { clearEmpty, clone, isString } from '@web-admin/utils'
import { STORAGE_TOKEN } from './constants'

export * from './constants'

let loadingInstance: ReturnType<typeof ElLoading.service>
export const $loading = {
  show: (text = 'Loading...') => {
    loadingInstance = ElLoading.service({
      body: true,
      // target: '#app',
      lock: true,
      text,
    })
  },
  hide: () => {
    loadingInstance.close()
  },
}

/**
 * `element-plus` 的 ElMessage
 */
export const $toast = {
  success: (message: string) => {
    ElMessage.success({ message, zIndex: 1000 })
  },
  error: (message: string) => {
    ElMessage.error({ message, zIndex: 1000 })
  },
  warning: (message: string) => {
    ElMessage.warning({ message, zIndex: 1000 })
  },
}

/**
 * `element-plus` 的 ElMessageBox
 */
export async function $confirm(message: string, title?: string, options?: ElMessageBoxOptions): Promise<boolean> {
  return new Promise((resolve) => {
    ElMessageBox.confirm(message, title, {
      confirmButtonText: options?.confirmButtonText ?? '确定',
      cancelButtonText: options?.cancelButtonText ?? '取消',
      type: options?.type ?? 'info',
    }).then(() => {
      resolve(true)
    }).catch(() => {
      resolve(false)
    })
    setTimeout(() => {
      const messageBox: HTMLElement | null = document.querySelector('.is-message-box')
      const button: HTMLElement | null = messageBox && messageBox.querySelector('.el-button--primary')
      button && button.blur()
    }, 100)
  })
}

/**
 * `element-plus` 的 ElMessageBox
 */
export async function $alert(message: string | VNode | (() => VNode), title?: string, options: ElMessageBoxOptions = {}) {
  return ElMessageBox.alert(message, title, {
    confirmButtonText: '确定',
    ...options,
  })
}

/**
 * 文件下载, 基于 `file-saver`
 */
export function $download(url: string, filename?: string) {
  saveAs(url, filename)
}

/**
 * 日期格式化, 基于 `date-fns`
 */
export function $dateFormat(date: string | number | Date, pattern = 'yyyy-MM-dd HH:mm:ss') {
  if (isString(date))
    date = parseISO(date)

  return format(toDate(date), pattern)
}

/**
 * 以 'yyyy-MM-dd' 格式返回当前日期。
 * @returns {string} 以 'yyyy-MM-dd' 格式返回的当前日期。
 */
export function $currentDate(): string {
  return $dateFormat(new Date(), 'yyyy-MM-dd')
}

/**
 * 通过 localStorage 标识, 判断是否登录
 */
export function $isLogin() {
  return !!$getToken()
}
/**
 * 获取 token
 */
export function $getToken() {
  return window.localStorage.getItem(STORAGE_TOKEN) ?? undefined
}
/**
 * 设置 token
 */
export function $setToken(token: string) {
  window.localStorage.setItem(STORAGE_TOKEN, token)
}
/**
 * 清除 token
 */
export function $clearToken() {
  window.localStorage.removeItem(STORAGE_TOKEN)
}

export async function $fetchAllByPages(page: number, fetcher: (page: number) => Promise<any[]>) {
  let records: any[] = []
  for (let index = 1; index <= page; index++)
    records = records.concat(await fetcher(index))

  return records
}

/**
 * 将数据导出为 excel 文件。
 * @param data - 要导出的 JSON 数据。
 * @param [filename='data.xlsx'] - 要下载的 execl 文件的名称。
 */
export function $exportToExcel(data: any[], filename = 'data.xlsx') {
  const workbook = XLSX.utils.book_new()
  const worksheet = XLSX.utils.json_to_sheet(data)
  worksheet['!cols'] = []
  for (const cellRef in worksheet) {
    if (cellRef[0] === '!')
      continue // 跳过特殊单元格
    const cell = worksheet[cellRef]
    cell.s = {
      alignment: {
        horizontal: 'center', // 水平居中对齐
        vertical: 'center', // 垂直居中
        wrapText: true, // 自动换行
      },
    }
    if (typeof cell.v === 'string')
      /^\d+(\.\d+)?$/.test(cell.v) && cell.v.length <= 10 ? cell.t = 'n' : cell.t = 's'
    else if (typeof cell.v === 'number')
      cell.t = 'n'
    else if (typeof cell.v === 'boolean')
      cell.t = 'b'
    else cell.t = 's'
    if (cell.v == null)
      delete cell.v

    worksheet['!cols'].push({ wpx: 150 })
  }

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
  // 将数据写入 Blob 对象，并指定编码为 UTF-8
  const xlsxData = XLSX.write(workbook, { bookType: 'xlsx', bookSST: false, type: 'buffer' })
  const blob = new Blob([xlsxData], { type: 'text/xlsx;charset=utf-8;' })
  const link = document.createElement('a')
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', filename)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}

/**
 * excel导出json
 * @param file excel文件
 */
export function $excelToJson(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsArrayBuffer(file.raw)
    reader.onload = () => {
      const buffer: any = reader.result
      const data = new Uint8Array(buffer)
      const workbook = XLSX.read(data, { type: 'array' })
      const firstSheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[firstSheetName]
      const jsonData = XLSX.utils.sheet_to_json(worksheet)
      resolve(jsonData)
    }
    reader.onerror = error => reject(error)
  })
}

/**
 * 根据value返回label
 * @example $valueToLabel(value,Arr)
 */
export const $valueToLabel = (
  value: any,
  Arr = [],
  key = 'value',
  nameKey = 'label',
): string => {
  const match = Arr.find((item) => {
    return item[key] === value
  })
  return match ? match[nameKey] : ''
}

export function $clearQuery(query: Record<string, any>) {
  const object = clone(query)
  if (Number(object.pageIndex) === 1)
    object.pageIndex = ''

  if (Number(object.pageSize) === 20)
    object.pageSize = ''

  return clearEmpty(object)
}

export function $formatMoney(val: number) {
  if (Number.isNaN(val))
    return ''
  return (val / 100).toFixed(2)
}

/**
 * Create water mark
 * Use `ffmpeg -i input.png -vf "colorlevels=rimin=0.99:gimin=0.99:bimin=0.99" output.png -y` for decode
 */
export function $watermark({ width = 100, height = 100, content, debug = false }: { width?: number; height?: number; content: string; debug?: boolean }) {
  const createWaterMark = (content: string) => {
    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height
    const ctx = canvas.getContext('2d')!
    ctx.rotate(-10 * Math.PI / 180)
    ctx.font = 'bold 18px serif'
    // font bold
    ctx.fillStyle = debug ? 'rgba(255,0,0,1)' : 'rgba(180, 180, 180, 0.08)'
    const lines = content.split(',')
    for (const line of lines) {
      ctx.fillText(line, 0, 50)
      ctx.translate(0, 30)
    }
    return canvas.toDataURL()
  }

  let watermarkDiv = document.querySelector('#watermark')
  if (!watermarkDiv)
    watermarkDiv = document.createElement('div')
  const base64Url = `url(${createWaterMark(content)})`
  watermarkDiv.setAttribute('id', 'watermark')
  watermarkDiv.setAttribute(
    'style',
    `
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9999999999;
      pointer-events: none;
      background-repeat: repeat;
      background-image: ${base64Url};
    `,
  )
  document.body.appendChild(watermarkDiv)
}

export function $aLinkDownload(url, name) {
  // 创建一个下载链接
  const link = document.createElement('a')
  link.target = '_blank'
  link.setAttribute('href', url)
  link.setAttribute('download', name)
  link.style.visibility = 'hidden'
  // 添加下载链接到页面上
  document.body.appendChild(link)

  // 触发点击事件以开始下载
  link.click()

  // 移除下载链接
  document.body.removeChild(link)
}

export function $exportToWord(data: string, name: string) {
  // 创建一个Blob对象
  const blob = new Blob(['\uFEFF', data], { type: 'application/msword' })

  // 生成Blob URL
  const blobUrl = URL.createObjectURL(blob)

  $aLinkDownload(blobUrl, `${name}.doc`)
  // 释放Blob URL
  URL.revokeObjectURL(blobUrl)
}

