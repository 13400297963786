<script setup lang="ts">
import zh from 'element-plus/es/locale/lang/zh-cn'

const route = useRoute()

useHead({
  title: '野象新媒体平台',
  meta: [{ name: 'description', content: '野象新媒体平台' }],
  link: [],
})

let loading = $ref(true)
onMounted(async () => {
  try {
    if ($isLogin() && !useAuthStore().account)
      await useAuthStore().initFetch()
  }
  finally {
    loading = false
  }
})

// 处理按钮点击后的焦点问题
function getButtonNode(target: HTMLElement | null) {
  if (!target)
    return null
  if (target.nodeName === 'BUTTON')
    return target

  return getButtonNode(target.parentNode as HTMLElement)
}

document.addEventListener('mouseup', (e) => {
  const target = getButtonNode(e.target)
  target && target.blur()
})

// TODO: 这里 el-drawer_body 的 bottom 如果为 16 ，在el-drawer中的表格翻到最后一页，分页器会盖住最后一行内容，原因未知...
const containerConfig: any = {
  'el-main': {
    top: 60,
    bottom: 0,
  },
  'el-drawer__body': {
    top: 94,
    bottom: 0,
  },
  'el-overlay-dialog': {
    top: 0,
    bottom: 0,
  },
}

/**
 * 判断是否在挂载点之内
 */
function checkIsInMountPoint(target: HTMLElement | null, container: string) {
  if (!target)
    return false
  if (target.classList?.contains(container))
    return true

  return checkIsInMountPoint(target.parentNode as HTMLElement, container)
}

/**
 * 获得容器节点
 */
function getContainerNode(target: HTMLElement | null, container: string) {
  if (!target)
    return null
  if (target.classList?.contains(container))
    return target

  return getContainerNode(target.parentNode as HTMLElement, container)
}

/**
 * 固定表格表头与分页
 */
function handleTableAndPaging(target: HTMLElement, container: string) {
  const tableHeader: any = target.querySelector('.el-table__header-wrapper')
  if (!tableHeader)
    return

  const noWhiteDrawer = checkIsInMountPoint(target, 'el-no-white-drawer')

  if (noWhiteDrawer) {
    containerConfig['el-drawer__body'].top = 62
    containerConfig['el-drawer__body'].bottom = 0
  }

  const isElDrawer = checkIsInMountPoint(target, 'el-drawer')
  if (isElDrawer) {
    const classList = target.parentNode?.classList ?? []
    for (const item of classList) {
      if (/^el-table-[0-9]{1,4}-[0-9]{1,4}$/.test(item)) {
        const positionSplit = item.split('-')
        containerConfig['el-drawer__body'].top = positionSplit[2]
        containerConfig['el-drawer__body'].bottom = positionSplit[3]
        break
      }
    }
  }

  // 处理表头
  const marginTop = containerConfig[container].top
  const tablePosition = tableHeader.parentNode.getBoundingClientRect()
  if (tablePosition.top < marginTop)
    tableHeader.style.transform = `translateY(${Math.abs(tablePosition.top - marginTop)}px)`
  else
    tableHeader.style.transform = 'translateY(0px)'

  // 处理分页
  const paginationNode: any = target.querySelector('.el-pagination-box')

  if (!paginationNode)
    return

  const parentBounding = paginationNode.parentNode.getBoundingClientRect()

  if (parentBounding.top > target.offsetHeight) {
    const parentBounding = paginationNode.parentNode.getBoundingClientRect()
    paginationNode.style.position = 'fixed'
    paginationNode.style.bottom = `${containerConfig[container].bottom}px`
    paginationNode.style.left = `${parentBounding.left}px`
    paginationNode.style.width = `${parentBounding.width}px`
  }

  if (tablePosition.y - target.offsetHeight + parentBounding.height > 0) {
    paginationNode.style.position = 'relative'
    paginationNode.style.left = '0px'
  }

  if (target.offsetHeight - parentBounding.top > 0) {
    paginationNode.style.position = 'relative'
    paginationNode.style.left = '0px'
  }

  // 处理自定义滚动条
  const tableScrollBox: any = target.querySelector('.table-scroll-box')
  if (!tableScrollBox)
    return
  if (tableHeader.scrollWidth > tableHeader.offsetWidth) {
    tableScrollBox.parentNode.style.display = 'block'
    tableScrollBox.style.width = `${tableHeader.scrollWidth}px`
  }

  // 隐藏表格自带滚动条
  const isHorizontal = target.querySelector('.is-horizontal')
  isHorizontal?.parentNode.classList.add('is-horizontal-none')
}

/**
 * 处理表格滚动条
 */
function handleTableScroll(target: HTMLElement, container: string) {
  const containerNode = getContainerNode(target, container)
  if (!containerNode)
    return

  const tableScroll = containerNode.querySelector('.table-scroll')
  if (!tableScroll)
    return

  tableScroll.scrollLeft = target.scrollLeft
}

/**
 * 处理自定义滚动条
 */
function handleCustomScroll(target: HTMLElement, container: string) {
  const containerNode = getContainerNode(target, container)
  if (!containerNode)
    return

  const headerWrapper = containerNode.querySelector('.el-table__header-wrapper')
  if (!headerWrapper)
    return
  headerWrapper.scrollLeft = target.scrollLeft

  const elScrollbar = containerNode.querySelector('.el-scrollbar__wrap')
  if (!elScrollbar)
    return
  elScrollbar.scrollLeft = target.scrollLeft
}

window.addEventListener('scroll', (e: Event) => {
  const target: any = e.target

  // 表格表头与分页
  for (const key of Object.keys(containerConfig)) {
    if (target.classList?.contains(key))
      handleTableAndPaging(target, key)
  }

  // 表格滚动条
  if (target.classList?.contains('el-table__header-wrapper')) {
    for (const key of Object.keys(containerConfig)) {
      if (checkIsInMountPoint(target, key))
        handleTableScroll(target, key)
    }
  }

  // 自定义滚动条
  if (target.classList?.contains('table-scroll')) {
    for (const key of Object.keys(containerConfig)) {
      if (checkIsInMountPoint(target, key))
        handleCustomScroll(target, key)
    }
  }
}, true)
setTimeout(() => {
  const navShrink = document.querySelector('.nav-shrink')
  navShrink?.addEventListener('click', (e: Event) => {
    setTimeout(() => {
      const elMain = document.querySelector('.el-main')
      const paginationNode: any = elMain?.querySelector('.el-pagination-box')
      if (!paginationNode)
        return
      const parentBounding = paginationNode.parentNode?.getBoundingClientRect()
      paginationNode.style.left = `${parentBounding.left}px`
      paginationNode.style.width = `${parentBounding.width}px`
    }, 300)
  })
}, 3000)

const applyMinWidth = computed(() => {
  const whiteList = ['login']
  return !whiteList.includes(route.name as string)
})
const font = reactive({
  color: 'rgba(0, 0, 0, .15)',
})
</script>

<template>
  <el-config-provider :locale="zh">
    <!-- TODO: Loading -->
    <RouterView v-if="!loading" :class="{ 'min-w-1440px': applyMinWidth }" />
  </el-config-provider>
</template>

<style lang="scss">
table {
  min-width: 100%;
}
.el-scrollbar__view {
  width: 100%;
}
.table-btn button {
  margin: 0 12px 0 0!important;
}
.el-table__inner-wrapper .el-table__header-wrapper {
  z-index: 100;
}
.is-horizontal-none {
  .is-horizontal {
    display: none;
  }
}
</style>
