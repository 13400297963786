import type { FilterConfigItem } from '~/types'
export * from '@web-admin/utils'

/**
 * 获取默认的查询条件
 */
export const getDefaultFilter = (config: FilterConfigItem[]): Object => {
  return config.reduce((sam: any, item: any) => {
    if (item.defaultValue !== undefined)
      sam[item.prop] = item.defaultValue
    return sam
  }, {})
}

/**
 * 费率格式化
 * @param rate 参数未进行 % 号转换，例如费率 0.01
 */
export const rateFormat = (rate: number) => {
  if (!rate)
    return '0%'
  else if (String(rate).length > 4)
    return `${(rate * 100).toFixed(2)}%`
  else
    return `${Math.round(rate * 100)}%`
}
export const addZero = (num: any) => { // 补0
  if (parseInt(num) < 10)
    num = `0${num}`

  return num
}
export function deepCopy(obj: any) {
  return JSON.parse(JSON.stringify(obj))
}

function checkAccessRight(account: any, right: { [key: string]: Array<{ [key: string]: number }> }) {
  if (!right)
    return true

  const verifyRight = {
    isRoleTypePass: true,
    isAppTypePass: true,
    isContentTypePass: true,
  }

  // 数据整理
  if (typeof right.roleType === 'number')
    right.roleType = [right.roleType]
  if (typeof right.appType === 'number')
    right.appType = [right.appType]
  if (typeof right.contentType === 'number')
    right.contentType = [right.contentType]

  // 验证角色
  if (right.roleType && !right.roleType.includes(account?.roleType ?? -1))
    verifyRight.isRoleTypePass = false

  // 验证应用类型
  if (right.appType && !right.appType.includes(account?.appType ?? -1))
    verifyRight.isAppTypePass = false

  // 验证内容类型
  if (right.contentType && !right.contentType.includes(account?.contentType ?? -1))
    verifyRight.isContentTypePass = false

  if (verifyRight.isRoleTypePass && verifyRight.isAppTypePass && verifyRight.isContentTypePass)
    return true

  return false
}

/**
 * 是否有权限访问
 */
export const isAccessRight = (account: any, meta: { menuPermission: Array<{ [key: string]: number }> } | { [key: string]: number }) => {
  if (!meta || !meta.menuPermission)
    return true

  if (!Array.isArray(meta.menuPermission))
    meta.menuPermission = [meta.menuPermission]

  for (const right of meta.menuPermission) {
    if (checkAccessRight(account, right))
      return true
  }

  return false
}
